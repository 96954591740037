<template>
  <section>
    <div class="container">

        <div class="row justify-content-center">
            <p>Lorem ipsum my resume!!</p>
        </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'MyResume',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
